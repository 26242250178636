// Home.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
let socket;

function Home({ isAuthenticated }) {
    // States and functions as before
    const [items, setItems] = useState([]);
    const [newItem, setNewItem] = useState({ url: '', type: '', name: '', tags: '', classes: '', crawl: false, linkSelector: '', moreSelector: '', useShadowDom: false, shadowHostSelector: '' });
    const [editItem, setEditItem] = useState({ url: '', type: '', name: '', tags: '', classes: '', crawl: false, linkSelector: '', moreSelector: '', _id: null, useShadowDom: false, shadowHostSelector: '' });
    const [editCrawl, setEditCrawl] = useState(false);
    const [apiKeys, setApiKeys] = useState([]);
    const [newApiKey, setNewApiKey] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isStopping, setIsStopping] = useState(false);
    const [shouldLoadLogs, setShouldLoadLogs] = useState(true);
    const logContainerRef = useRef(null);
    const [logs, setLogs] = useState(() => {
        const savedLogs = localStorage.getItem('processingLogs');
        return savedLogs ? JSON.parse(savedLogs) : [];
    });
    const [sitemaps, setSitemaps] = useState([]);
    const [selectedSitemap, setSelectedSitemap] = useState('');
    const [processingLogs, setProcessingLogs] = useState([]);
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return isNaN(date.getTime()) ? 'Date not available' : date.toLocaleString();
    };
    const [logOffset, setLogOffset] = useState(0);
    const [hasMoreLogs, setHasMoreLogs] = useState(true);
    const navigate = useNavigate();
    // State for controlling visibility of the "Create New Sitemap" form
    const [showNewSitemapForm, setShowNewSitemapForm] = useState(false);

    const fetchProcessingLogs = async () => {
        if (!isAuthenticated) return;

        try {
            const response = await fetch(`/api/processing-logs?offset=${logOffset}&limit=10`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                if (data.logs && Array.isArray(data.logs)) {
                    setProcessingLogs(prevLogs => [...prevLogs, ...data.logs]);
                    setLogOffset(prevOffset => prevOffset + data.logs.length);
                    setHasMoreLogs(data.hasMore);
                } else {
                    console.error('Expected an array of logs, received:', data);
                }
            } else {
                const errorDetails = await response.text();
                console.error('Failed to fetch processing logs:', errorDetails);
            }
        } catch (error) {
            console.error('Error fetching processing logs:', error);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            setProcessingLogs([]);
            setLogOffset(0);
            fetchProcessingLogs();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (!socket) {
            socket = io('/');

            socket.on('processingUpdate', message => {
                console.log("Received message:", message);
                addLog(message.message);
                handleCountUpdate(message);
            });
        }

        return () => {
            if (socket) {
                socket.disconnect();
                socket = null;
            }
        };
    }, [isAuthenticated]);

    const [counters, setCounters] = useState(() => {
        const savedCounters = localStorage.getItem('processingCounters');
        return savedCounters ? JSON.parse(savedCounters) : {
            sitemapsProcessed: 0,
            articlesProcessed: 0,
            articlesUpdated: 0,
            articlesSkipped: 0,
            newArticles: 0,
            deletedArticles: 0
        };
    });

    const handleCountUpdate = (data) => {
        const updateCounters = (type, count) => {
            setCounters(prev => {
                const newCounters = { ...prev, [type]: prev[type] + count };
                localStorage.setItem('processingCounters', JSON.stringify(newCounters));
                return newCounters;
            });
        };

        switch (data.type) {
            case 'sitemapsProcessed':
                updateCounters('sitemapsProcessed', data.count);
                break;
            case 'articlesProcessed':
                updateCounters('articlesProcessed', data.count);
                break;
            case 'articlesUpdated':
                updateCounters('articlesUpdated', data.count);
                break;
            case 'articlesSkipped':
                updateCounters('articlesSkipped', data.count);
                break;
            case 'newArticles':
                updateCounters('newArticles', data.count);
                break;
            case 'deletedArticles':
                updateCounters('deletedArticles', data.count);
                break;
            case 'error':
                console.error(`Error: ${data.message}`);
                break;
            case 'urlProcessed':
            case 'info':
            case 'stop':
                console.log(`Notice: ${data.message}`);
                break;
            default:
                console.log(`Unhandled type in handleCountUpdate: ${data.type}`, data);
                break;
        }
    };

    function DisplayCounts({ counters }) {
        return (
            <>
                <h2 className={"mt-10 text-white text-md font-bold"}>Current process</h2>
                <div className="mt-2 mb-8 p-4 bg-slate-900 rounded text-slate-200 text-xs shadow-2xl">
                    <ul className={"font-mono text-sky-400"}>
                        <li>Sitemaps Processed: {counters.sitemapsProcessed}</li>
                        <li>Articles Processed: {counters.articlesProcessed}</li>
                        <li>Articles Updated: {counters.articlesUpdated}</li>
                        <li>Articles Skipped: {counters.articlesSkipped}</li>
                        <li>New Articles: {counters.newArticles}</li>
                        <li>Deleted Articles: {counters.deletedArticles}</li>
                    </ul>
                </div>
            </>
        );
    }

    useEffect(() => {
        const fetchSitemaps = async () => {
            const response = await fetch('/api/sitemaps', {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            });
            if (response.ok) {
                const fetchedSitemaps = await response.json();
                setSitemaps(fetchedSitemaps);
            }
        };

        if (isAuthenticated) {
            fetchSitemaps();
        }
    }, [isAuthenticated]);

    const formatInput = (input) => {
        return input.replace(/[\s_\/]+/g, '-').toLowerCase();
    };

    const handleApiKeyChange = (e) => {
        const formattedApiKey = formatInput(e.target.value);
        setNewApiKey(formattedApiKey);
    };

    const labelColors = {
        'Summary Embedding': 'text-purple-500',
        'Content Embedding': 'text-pink-500',
    };

    const getLabelColor = (type) => {
        return labelColors[type];
    };

    const addLog = (message) => {
        setLogs((prevLogs) => {
            const updatedLogs = [...prevLogs, `${new Date().toLocaleTimeString()}: ${message}`];
            localStorage.setItem('processingLogs', JSON.stringify(updatedLogs));
            return updatedLogs;
        });
    };

    const clearLogs = () => {
        setLogs([]);
        localStorage.removeItem('processingLogs');
        setCounters({
            sitemapsProcessed: 0,
            articlesProcessed: 0,
            articlesUpdated: 0,
            articlesSkipped: 0,
            newArticles: 0,
            deletedArticles: 0
        });
        localStorage.removeItem('processingCounters');
    };

    const copyLogsToClipboard = async () => {
        const logsString = logs.join("\n");
        try {
            await navigator.clipboard.writeText(logsString);
            alert('Logs copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy logs: ', err);
            alert('Failed to copy logs to clipboard.');
        }
    };

    useEffect(() => {
        if (logContainerRef.current) {
            const { scrollHeight } = logContainerRef.current;
            logContainerRef.current.scrollTop = scrollHeight;
        }
    }, [logs]);

    const handleStartProcessing = async (sitemapId) => {
        setIsProcessing(true);
        try {
            const response = await fetch(`/api/sitemaps/start-processing/${sitemapId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            addLog(data.message);
        } catch (error) {
            console.error('Error starting processing:', error);
            addLog('Error starting processing: ' + error.message);
        }
        setIsProcessing(false);
    };

    const handleStopProcessing = async () => {
        setIsStopping(true);
        try {
            const response = await fetch('/api/sitemaps/stop-processing', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            addLog(data.message);
        } catch (error) {
            console.error('Error stopping processing:', error);
            addLog('Failed to stop processing.');
        }
        setIsProcessing(false);
    };

    const checkProcessingStatus = async () => {
        try {
            const response = await fetch('/api/sitemaps/status', {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            });
            const data = await response.json();
            setIsProcessing(data.isProcessing && !data.isComplete);
        } catch (error) {
            console.error('Error fetching processing status:', error);
        }
    };

    function ProcessingMessage() {
        return (
            <div className="p-0">
                <h2 className="text-2xl text-white font-bold mb-4">Sitemaps</h2>
                <p className="text-red-500 text-xs">
                    No changes to sitemaps or API keys while sitemaps are processing
                </p>
            </div>
        );
    }

    useEffect(() => {
        if (isAuthenticated) {
            checkProcessingStatus().then(() => {
                if (!isProcessing) {
                    const savedLogs = JSON.parse(localStorage.getItem('processingLogs')) || [];
                    setLogs(savedLogs);
                }
            });
        }
    }, [isAuthenticated, isProcessing]);

    const handleEdit = (sitemap) => {
        if (sitemap.url === 'manual-url') {
            navigate(`/edit-manual-sitemap/${sitemap._id}`);
        } else {
            setEditItem({
                name: sitemap.name,
                url: sitemap.url,
                type: toEnumValue(sitemap.vectorizerApproach),
                tags: Array.isArray(sitemap.tags) ? sitemap.tags.join(', ') : (sitemap.tags || ''),
                classes: Array.isArray(sitemap.classes) ? sitemap.classes.join(', ') : (sitemap.classes || ''),
                crawl: sitemap.crawl || false,
                linkSelector: sitemap.linkSelector || '',
                moreSelector: sitemap.moreSelector || '',
                useShadowDom: sitemap.useShadowDom || false,
                _id: sitemap._id,
                shadowHostSelector: sitemap.shadowHostSelector || ''
            });
            setEditCrawl(sitemap.crawl || false);
        }
    };



    function toEnumValue(friendlyName) {
        const mapping = {
            'Summary Embedding': 'SummaryEmbedding',
            'Content Embedding': 'FullContentEmbedding',
        };
        return mapping[friendlyName] || friendlyName;
    }

    const handleEditChange = (e) => {
        const { name, value, type, checked } = e.target;

        setEditItem({
            ...editItem,
            [name]: type === 'checkbox' ? checked : value
        });

        // Log the state to check if it's working properly
        console.log({ [name]: type === 'checkbox' ? checked : value, editItem });
    };


    const handleDelete = async (itemId) => {
        const token = localStorage.getItem('token');

        if (!window.confirm("Are you sure you want to delete this sitemap?")) {
            return;
        }

        try {
            const response = await fetch(`/api/sitemaps/${itemId}`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (response.ok) {
                setItems(items.filter(item => item._id !== itemId));
            } else {
                if (response.headers.get('Content-Type')?.includes('application/json')) {
                    const errorData = await response.json();
                    console.error('Delete error:', errorData.message);
                    alert(`Failed to delete the sitemap: ${errorData.message}`);
                } else {
                    const text = await response.text();
                    console.error('Delete error:', text);
                    alert('Failed to delete the sitemap. Please try again.');
                }
            }
        } catch (error) {
            console.error('Network error when trying to delete sitemap:', error);
            alert('Network error when trying to delete sitemap.');
        }
    };

    // Toggle function for showing and hiding the form
    const toggleNewSitemapForm = () => setShowNewSitemapForm(prev => !prev);


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const formattedValue = name === 'name' ? formatInput(value) : (type === 'checkbox' ? checked : value);

        if (name === 'crawl') {
            setNewItem({ ...newItem, crawl: checked });
        } else {
            setNewItem({ ...newItem, [name]: formattedValue });
        }
    };


    const handleManualSitemapChange = (e) => {
        const { name, value } = e.target;
        setNewItem({ ...newItem, [name]: value });
    };

    const handleManualSitemapSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const decodedToken = JSON.parse(atob(base64));

        const manualUrlsArray = newItem.manualUrls.split(',').map(url => url.trim());
        const tagsArray = newItem.tags.split(',').map(tag => tag.trim());
        const classesArray = newItem.classes.split(',').map(cls => cls.trim());

        const sitemapData = {
            userId: decodedToken.userId,
            name: newItem.name,
            url: newItem.url,
            vectorizerApproach: newItem.type,
            manualUrls: manualUrlsArray,
            tags: tagsArray,
            classes: classesArray
        };

        try {
            const response = await fetch('/api/sitemaps', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(sitemapData)
            });

            if (response.ok) {
                const newSitemap = await response.json();
                setItems([...items, newSitemap]);
                setNewItem({ name: '', url: '', manualUrls: '', tags: '', classes: '', type: '' });
            } else {
                throw new Error('Failed to create sitemap');
            }
        } catch (error) {
            console.error('Error creating sitemap:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const decodedToken = JSON.parse(atob(base64));

        const tags = newItem.tags ? newItem.tags.split(',').map(tag => tag.trim()) : [];
        const classes = newItem.classes ? newItem.classes.split(',').map(cls => cls.trim()) : [];

        const sitemapData = {
            userId: decodedToken.userId,
            name: newItem.name,
            url: newItem.url,
            vectorizerApproach: newItem.type,
            tags: tags,
            classes: classes,
            crawl: newItem.crawl,
            linkSelector: newItem.linkSelector,
            moreSelector: newItem.moreSelector,
            useShadowDom: newItem.useShadowDom,
            shadowHostSelector: newItem.shadowHostSelector || '' // Ensure this is included, even if it's empty
        };

        setShowNewSitemapForm(false); // Hide the form after submission
        try {
            const response = await fetch('/api/sitemaps', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(sitemapData),
            });
            const result = await response.json();
            if (response.ok) {
                const newItemWithCorrectStructure = {
                    ...result,
                    type: formatVectorizerApproach(result.vectorizerApproach),
                };
                setItems([...items, newItemWithCorrectStructure]);
                setNewItem({ url: '', type: '', name: '', tags: '', classes: '', crawl: false, linkSelector: '', moreSelector: '', useShadowDom: false, shadowHostSelector: '' });
            } else {
                throw new Error(result.message || 'Failed to add sitemap');
            }
        } catch (error) {
            console.error('Failed to add sitemap:', error);
        }
    };



    useEffect(() => {
        fetchApiKeys();
        const fetchSitemaps = async () => {
            const token = localStorage.getItem('token');
            const response = await fetch('/api/sitemaps', {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (response.ok) {
                const data = await response.json();
                const normalizedData = data.map(item => ({
                    ...item,
                    type: formatVectorizerApproach(item.vectorizerApproach),
                }));
                setItems(normalizedData);
            } else {
                console.error('Failed to fetch sitemaps');
            }
        };

        if (isAuthenticated) {
            fetchSitemaps();
        }
    }, [isAuthenticated]);

    const fetchApiKeys = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('/api/apikeys', {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            if (response.ok) {
                const keys = await response.json();
                setApiKeys(keys);
            } else {
                console.error('Failed to fetch API keys');
            }
        } catch (error) {
            console.error('Error fetching API keys:', error);
        }
    };

    function formatVectorizerApproach(approach) {
        const mapping = {
            'SummaryEmbedding': 'Summary Embedding',
            'FullContentEmbedding': 'Content Embedding',
        };
        return mapping[approach] || approach;
    }

    const handleSave = async (e, id) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        const tagsArray = editItem.tags ? editItem.tags.split(',').map(tag => tag.trim()) : [];
        const classesArray = editItem.classes ? editItem.classes.split(',').map(cls => cls.trim()) : [];

        const updatedData = {
            name: editItem.name,
            url: editItem.url,
            vectorizerApproach: editItem.type,
            tags: tagsArray,
            classes: classesArray,
            crawl: editItem.crawl,
            linkSelector: editItem.linkSelector,
            moreSelector: editItem.moreSelector,
            useShadowDom: editItem.useShadowDom,
            shadowHostSelector: editItem.shadowHostSelector || ''
        };

        try {
            const response = await fetch(`/api/sitemaps/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(updatedData),
            });

            if (response.ok) {
                // Update the local state with the newly edited item
                const updatedSitemap = await response.json();
                setItems(items.map((item) => (item._id === id ? { ...item, ...updatedSitemap } : item)));
                setEditItem({ url: '', type: '', name: '', tags: '', classes: '', crawl: false, linkSelector: '', moreSelector: '', _id: null, useShadowDom: false });
            } else {
                console.error('Failed to save changes.');
            }

        } catch (error) {
            console.error('Error saving changes:', error);
        }
    };


    const createApiKey = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch('/api/apikeys', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ name: newApiKey }),
        });
        if (response.ok) {
            const newKey = await response.json();
            setApiKeys([...apiKeys, newKey]);
            setNewApiKey('');
        } else {
            console.error('Failed to create API key');
            const errorResponse = await response.json();
            alert(`Error: ${errorResponse.message}`);
        }
    };

    const deleteApiKey = async (keyId) => {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/apikeys/${keyId}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}` },
        });
        if (response.ok) {
            setApiKeys(apiKeys.filter(key => key._id !== keyId));
        } else {
            console.error('Failed to delete API key');
        }
    };

    const copyToClipboard = text => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('API key copied to clipboard!');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    function formatTagsAndClasses(tags, classes) {
        const tagString = Array.isArray(tags) ? tags.map(tag => `<${tag}>`).join(', ') : (tags || '');
        const classString = Array.isArray(classes) ? classes.map(cls => '.' + cls).join(', ') : (classes || '');
        let combinedString = '';

        if (tagString && classString) {
            combinedString = `${tagString}, ${classString}`;
        } else {
            combinedString = tagString + classString;
        }

        return combinedString;
    }

    const formatProcessingTime = (startTime, endTime, interruptedAt) => {
        if (!startTime || (!endTime && !interruptedAt)) return "cannot be calculated";

        const end = new Date(endTime || interruptedAt || new Date());
        const start = new Date(startTime);
        const diff = end - start;

        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        let formattedTime = "";

        if (hours > 0) formattedTime += `${hours}hr `;
        if (minutes > 0 || hours > 0) formattedTime += `${minutes}mins`;

        return formattedTime || "Less than a minute";
    };

    return (
        <>
            <div className="container mt-10 mx-auto p-8 text-left shadow-2xl border border-slate-700 rounded-xl bg-slate-800 bg-opacity-40">
                {isAuthenticated ? (
                    <div className="p-6">
                        {isProcessing ? (
                            <ProcessingMessage />
                        ) : (
                            <>
                            <div className="flex items-center justify-between">
                                <h2 className="text-2xl text-white font-bold mb-4">Sitemaps</h2>
                                <div className="flex space-x-4">
                                    <button
                                        onClick={toggleNewSitemapForm}
                                        className="shadow-2xl bg-slate-900 text-slate-200 px-3 border border-slate-700 py-2 rounded text-sm font-medium hover:bg-slate-700"
                                    >
                                        {showNewSitemapForm ? 'Hide New Sitemap Form' : 'Create New Sitemap'}
                                    </button>
                                    <button
                                        onClick={() => navigate('/manual-sitemap')}
                                        className="shadow-2xl bg-slate-900 text-slate-200 px-3 border border-slate-700 py-2 rounded text-sm font-medium hover:bg-slate-700">
                                        Create Manual Sitemap
                                    </button>
                                </div>
                            </div>


                            {/* Conditionally render the form based on the state */}
                            {showNewSitemapForm && (
                                <>
                                    <h2 className={"mt-4 text-slate-500"}>Add a sitemap</h2>
                                    <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4 mt-4">
                                        {/* First Row: Name and URL */}
                                        <div className="col-span-1">
                                            <input
                                                type="text"
                                                name="name"
                                                value={newItem.name}
                                                onChange={handleChange}
                                                className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                placeholder="Sitemap Name"
                                                required
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <input
                                                type="text"
                                                name="url"
                                                value={newItem.url}
                                                onChange={handleChange}
                                                className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                placeholder="Sitemap URL"
                                                required
                                            />
                                        </div>

                                        {/* Second Row: Tags and Classes */}
                                        <div className="col-span-1">
                                            <input
                                                type="text"
                                                name="tags"
                                                value={newItem.tags}
                                                onChange={handleChange}
                                                className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                placeholder="Tags (comma-separated)"
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <input
                                                type="text"
                                                name="classes"
                                                value={newItem.classes}
                                                onChange={handleChange}
                                                className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                placeholder="Classes (comma-separated)"
                                            />
                                        </div>

                                        {/* Crawl Checkbox */}
                                        <div className="col-span-1 flex items-center">
                                            <input
                                                type="checkbox"
                                                name="crawl"
                                                checked={newItem.crawl}
                                                onChange={handleChange}
                                                className="mr-2"
                                            />
                                            <label className="text-white">Crawl</label>
                                        </div>

                                        {/* Conditionally Show Link Selector, More Selector, Shadow DOM */}
                                        {newItem.crawl && (
                                            <>
                                                <div className="col-span-1">
                                                    <input
                                                        type="text"
                                                        name="linkSelector"
                                                        value={newItem.linkSelector}
                                                        onChange={handleChange}
                                                        className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                        placeholder="Link Selector (CSS)"
                                                    />
                                                </div>
                                                <div className="col-span-1">
                                                    <input
                                                        type="text"
                                                        name="moreSelector"
                                                        value={newItem.moreSelector}
                                                        onChange={handleChange}
                                                        className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                        placeholder="More Selector (CSS)"
                                                    />
                                                </div>

                                                <div className="col-span-1 flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        name="useShadowDom"
                                                        checked={newItem.useShadowDom}
                                                        onChange={handleChange}
                                                        className="mr-2"
                                                    />
                                                    <label className="text-white">Use Shadow DOM</label>
                                                </div>

                                                {newItem.useShadowDom && (
                                                    <div className="col-span-1">
                                                        <input
                                                            type="text"
                                                            name="shadowHostSelector"
                                                            value={newItem.shadowHostSelector}
                                                            onChange={handleChange}
                                                            className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                            placeholder="Shadow Host Selector"
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {/* Type Dropdown and Action Buttons */}
                                        <div className="col-span-1">
                                            <select
                                                name="type"
                                                value={newItem.type}
                                                onChange={handleChange}
                                                className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                required
                                            >
                                                <option value="">Select Type</option>
                                                <option value="SummaryEmbedding">Summary Embedding</option>
                                                <option value="FullContentEmbedding">Content Embedding</option>
                                            </select>
                                        </div>

                                        {/* Action Buttons */}
                                        <div className="col-span-2 flex justify-end space-x-2 mt-2">
                                            <button
                                                type="submit"
                                                className="p-2 w-auto bg-slate-800 text-white rounded hover:bg-blue-500"
                                            >
                                                Add Sitemap
                                            </button>
                                            <button
                                                type="button"
                                                onClick={toggleNewSitemapForm}
                                                className="p-2 w-auto bg-slate-800 text-white rounded hover:bg-red-500"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        </form>
                                    </>
                        )}


                        <h2 className={"mt-4 text-slate-500"}>Existing sitemaps</h2>
                                <ul className="mt-4">
                                    {items.map((item) => (
                                        <li key={item._id} className="py-2 px-4 bg-slate-900 mb-2 rounded shadow-2xl">
                                            {editItem._id === item._id ? (
                                                <form onSubmit={(e) => handleSave(e, editItem._id)}
                                                      className="grid grid-cols-2 gap-4">
                                                    {/* First Row: Name and URL */}
                                                    <div className="col-span-1">
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            value={editItem.name}
                                                            onChange={handleEditChange}
                                                            className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                            placeholder="Sitemap Name"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <input
                                                            type="text"
                                                            name="url"
                                                            value={editItem.url}
                                                            onChange={handleEditChange}
                                                            className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                            placeholder="Sitemap URL"
                                                        />
                                                    </div>

                                                    {/* Second Row: Tags and Classes */}
                                                    <div className="col-span-1">
                                                        <input
                                                            type="text"
                                                            name="tags"
                                                            value={editItem.tags}
                                                            onChange={handleEditChange}
                                                            className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                            placeholder="Tags (comma-separated)"
                                                        />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <input
                                                            type="text"
                                                            name="classes"
                                                            value={editItem.classes}
                                                            onChange={handleEditChange}
                                                            className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                            placeholder="Classes (comma-separated)"
                                                        />
                                                    </div>

                                                    {/* Crawl Checkbox */}
                                                    <div className="col-span-1 flex items-center">
                                                        <input
                                                            type="checkbox"
                                                            name="crawl"
                                                            checked={editItem.crawl}
                                                            onChange={handleEditChange}
                                                            className="mr-2"
                                                        />
                                                        <label className="text-white">Crawl</label>
                                                    </div>

                                                    {/* Conditionally Show Link Selector, More Selector, Shadow DOM */}
                                                    {editItem.crawl && (
                                                        <>
                                                            <div className="col-span-1 flex items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    name="useShadowDom"
                                                                    checked={editItem.useShadowDom}
                                                                    onChange={handleEditChange}
                                                                    className="mr-2"
                                                                />
                                                                <label className="text-white">Use Shadow DOM</label>
                                                            </div>
                                                            <div className="col-span-1">
                                                                <input
                                                                    type="text"
                                                                    name="linkSelector"
                                                                    value={editItem.linkSelector}
                                                                    onChange={handleEditChange}
                                                                    className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                                    placeholder="Link Selector (CSS)"
                                                                />
                                                            </div>
                                                            {editItem.useShadowDom && (
                                                                <div className="col-span-1">
                                                                    <input
                                                                        type="text"
                                                                        name="shadowHostSelector"
                                                                        value={editItem.shadowHostSelector}
                                                                        onChange={handleEditChange}
                                                                        className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                                        placeholder="Shadow Host Selector"
                                                                    />
                                                                </div>
                                                            )}
                                                            <div className="col-span-1">
                                                                <input
                                                                    type="text"
                                                                    name="moreSelector"
                                                                    value={editItem.moreSelector}
                                                                    onChange={handleEditChange}
                                                                    className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                                    placeholder="More Selector (CSS)"
                                                                />
                                                            </div>
                                                        </>
                                                    )}

                                                    {/* Type Dropdown */}
                                                    <div className="col-span-1">
                                                        <select
                                                            name="type"
                                                            value={editItem.type}
                                                            onChange={handleEditChange}
                                                            className="p-2 mb-2 w-full bg-slate-900 text-white border border-slate-700 rounded"
                                                            required
                                                        >
                                                        <option value="SummaryEmbedding">Summary Embedding</option>
                                                            <option value="FullContentEmbedding">Content Embedding
                                                            </option>
                                                        </select>
                                                    </div>

                                                    {/* Action Buttons */}
                                                    <div className="col-span-2 flex justify-end space-x-2 mt-2">
                                                        <button
                                                            type="submit"
                                                            className="p-2 w-auto bg-slate-800 text-white rounded hover:bg-green-500"
                                                        >
                                                            Save Changes
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => setEditItem({
                                                                url: '',
                                                                type: '',
                                                                name: '',
                                                                tags: '',
                                                                classes: '',
                                                                crawl: false,
                                                                linkSelector: '',
                                                                moreSelector: '',
                                                                _id: null
                                                            })}
                                                            className="p-2 w-auto bg-slate-800 text-white rounded hover:bg-red-500"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </form>
                                            ) : (
                                                <div className="flex w-full items-center flex-col mb-2">
                                                    {/* Non-edit view */}
                                                    <div className="flex w-full items-center">
                                                        <div className="mr-4">
                                                            <span
                                                                className="text-xs text-yellow-500 flex-1">{item.name}</span>
                                                        </div>
                                                        <div className="flex-1 min-w-0 overflow-x-scroll">
                                                            <a
                                                                href={item.url}
                                                                className="text-slate-400 text-xs font-mono hover:text-lime-400 truncate overflow-ellipsis"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                title={item.url}
                                                            >
                                                                {item.url}
                                                            </a>
                                                        </div>
                                                        <span
                                                            className={`mx-4 text-xs ${getLabelColor(item.type)}`}>{item.type}</span>
                                                        <div>
                                                            <button onClick={() => handleEdit(item)}
                                                                    className="text-gray-500 hover:text-yellow-400 mr-2">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="1.5"
                                                                    stroke="currentColor"
                                                                    className="w-4 h-4"
                                                                >
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 012.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"/>
                                                                </svg>
                                                            </button>
                                                            <button onClick={() => handleDelete(item._id)}
                                                                    className="text-gray-500 hover:text-red-500">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="1.5"
                                                                    stroke="currentColor"
                                                                    className="w-4 h-4"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="flex w-full justify-start font-mono text-xs text-slate-600">
                                                        {(item.tags.length > 0 || item.classes.length > 0) && (
                                                            <span>collecting: {formatTagsAndClasses(item.tags, item.classes)}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </li>


                                    ))}
                                </ul>

                                <div>
                                    <h2 className="text-xl text-white font-bold mt-8 mb-4">API Keys</h2>
                                    <input
                                        type="text"
                                        value={newApiKey}
                                        onChange={handleApiKeyChange}
                                        placeholder="API key name"
                                        className="shadow-2xl rounded py-2 px-4 mr-2 bg-slate-900 font-mono text-xs focus-visible:outline-0 w-1/4"
                                    />
                                    <button onClick={createApiKey}
                                            className="shadow-2xl bg-lime-400 text-slate-800 px-3 py-1 rounded text-sm font-extrabold hover:bg-lime-300">
                                        +
                                    </button>

                                    <ul className="mt-4">
                                        {apiKeys.map(key => (
                                            <li key={key._id}
                                                className="flex items-center justify-between py-2 px-4 bg-slate-900 mb-2 rounded shadow-2xl">
                                                <span
                                                    className="text-slate-400 text-xs font-mono truncate overflow-ellipsis"><span
                                                    className={"text-lime-500"}>{key.name}</span>: {key.key}</span>
                                                <div>
                                                    <button onClick={() => copyToClipboard(key.key)}
                                                            className="text-gray-500 hover:text-green-400 mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24"
                                                             stroke-width="1.5" stroke="currentColor"
                                                             className="w-4 h-4">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"/>
                                                        </svg>
                                                    </button>
                                                    <button onClick={() => deleteApiKey(key._id)}
                                                            className="text-gray-500 hover:text-red-400">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24"
                                                             stroke-width="1.5" stroke="currentColor"
                                                             className="w-4 h-4">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )}
                    </div>
                ) : (
                    <div className="text-center">
                        <h2 className="text-xl font-semibold">Please log in to access Vectorizer-2.</h2>
                    </div>
                )}
            </div>
            <div
                className={"container w-full mt-10 mx-auto p-14 text-left shadow-2xl border border-slate-700 rounded-xl bg-slate-800 bg-opacity-40"}>
                {isAuthenticated && (
                    <>
                        <div className="flex justify-between items-center mb-4 text-xs">
                            <h2 className="text-xl text-white font-bold">Process Sitemaps</h2>
                            <div className="flex items-center space-x-2">
                                {!isProcessing && (
                                    <>
                                        <select value={selectedSitemap}
                                                onChange={e => setSelectedSitemap(e.target.value)}
                                                className="rounded bg-slate-900 text-white p-2">
                                            <option value="">All Sitemaps</option>
                                            {sitemaps.map(sitemap => (
                                                <option key={sitemap._id} value={sitemap._id}>{sitemap.name}</option>
                                            ))}
                                        </select>
                                        <button onClick={() => handleStartProcessing(selectedSitemap)}
                                                disabled={isProcessing}
                                                className="bg-lime-400 text-slate-800 px-4 py-2 font-bold rounded">
                                            Start Processing
                                        </button>
                                    </>
                                )}
                                {isProcessing && (
                                    <button onClick={handleStopProcessing}
                                            className="bg-red-700 text-white py-2 px-4 rounded shadow font-bold hover:bg-red-500">
                                        Cancel Processing
                                    </button>
                                )}
                                {logs.length > 0 && !isProcessing && (
                                    <>
                                        <button onClick={clearLogs}
                                                className="bg-blue-500 py-2 px-4 rounded shadow font-bold text-white hover:bg-blue-400">
                                            Clear Logs
                                        </button>
                                        <button onClick={copyLogsToClipboard}
                                                className="text-slate-500"
                                                title="Copy logs">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"/>
                                            </svg>
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>

                        {logs.length > 0 && (
                            <>
                                <div
                                    className="mt-4 h-full max-h-96 overflow-y-scroll bg-slate-900 text-xs shadow-2xl font-mono text-lime-400 p-6 rounded"
                                     ref={logContainerRef}>
                                    {logs.map((log, index) => (
                                        <div key={index}>{log}</div>
                                    ))}
                                </div>
                                <DisplayCounts counters={counters} />
                            </>
                        )}
                    </>
                )}
                {isAuthenticated && processingLogs.length > 0 ? (
                    <div className="border-t-2 border-slate-700">
                        <h2 className="text-sky-400 font-bold mt-4 mb-4">Prior processing logs</h2>
                        {processingLogs.map((log, index) => (
                            <div key={index} className="text-slate-400 bg-slate-900 p-4 rounded shadow-xl text-xs mb-2 font-mono">
                                <p className={"text-sky-400"}>
                                    {formatDate(log.startTime)}: <span className={"text-white font-bold"}>Sitemap {log.sitemapName}</span>
                                    <span className={`ml-2 ${!log.endTime ? (log.interruptedAt ? 'text-orange-400' : 'text-red-500') : 'text-lime-400'}`}>
                                        {!log.endTime ? (log.interruptedAt ? 'Interrupted' : 'Interrupted - command line') : 'Completed'}
                                    </span>
                                </p>
                                <ul>
                                    <li className={"text-purple-500"}>Processing time: {formatProcessingTime(log.startTime, log.endTime || log.interruptedAt)}</li>
                                    <li>Articles Processed: {log.articlesProcessed}</li>
                                    <li>Articles Deleted: {log.articlesDeleted}</li>
                                    <li>Articles Skipped: {log.articlesSkipped}</li>
                                    <li>Articles Created: {log.articlesCreated}</li>
                                    <li>Articles Updated: {log.articlesUpdated}</li>
                                </ul>
                            </div>
                        ))}
                        {hasMoreLogs ? (
                            <button onClick={fetchProcessingLogs} className="bg-slate-900 text-slate-500 hover:text-slate-900 font-bold text-sm w-full shadow-xl py-2 px-4 rounded hover:bg-lime-500 mt-4">
                                Load More Logs
                            </button>
                        ) : (
                            <p>No more logs.</p>
                        )}
                    </div>
                ) : (
                    <p className="text-slate-500">No processing logs available.</p>
                )}
            </div>
        </>
    );
}

export default Home;
