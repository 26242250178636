import React, { useState, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';

const socket = io('/');

function Knowledge() {
    const [knowledgeSources, setKnowledgeSources] = useState([]);
    const [file, setFile] = useState(null);
    const [name, setName] = useState('');
    const [type, setType] = useState([]);
    const [sitemaps, setSitemaps] = useState([]);
    const [selectedSitemap, setSelectedSitemap] = useState('');
    const [formType, setFormType] = useState('pdf');
    const [editingId, setEditingId] = useState(null);
    const [editType, setEditType] = useState([]);
    const [editName, setEditName] = useState('');
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [filterTypes, setFilterTypes] = useState([]);

    const [processing, setProcessing] = useState(() => {
        const storedProcessing = localStorage.getItem('processing');
        return storedProcessing ? JSON.parse(storedProcessing) : {};
    });

    const [chunkCounts, setChunkCounts] = useState(() => {
        const storedChunkCounts = localStorage.getItem('chunkCounts');
        return storedChunkCounts ? JSON.parse(storedChunkCounts) : {};
    });

    useEffect(() => {
        async function fetchKnowledgeSources() {
            try {
                const response = await axios.get('/api/knowledgeSources', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                const { sources } = response.data;
                console.log('Fetched knowledge sources:', sources);

                const counts = {};
                sources.forEach((source) => {
                    counts[source._id] = source.knowledgeChunksCount;
                });

                setKnowledgeSources(sources);
                setChunkCounts((prev) => ({ ...prev, ...counts }));
            } catch (error) {
                console.error('Error fetching knowledge sources:', error);
            }
        }

        async function fetchSitemaps() {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('/api/sitemaps', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setSitemaps(response.data);
            } catch (error) {
                console.error('Error fetching sitemaps:', error);
            }
        }

        fetchKnowledgeSources();
        fetchSitemaps();
    }, []);

    useEffect(() => {
        localStorage.setItem('processing', JSON.stringify(processing));
    }, [processing]);

    useEffect(() => {
        localStorage.setItem('chunkCounts', JSON.stringify(chunkCounts));
    }, [chunkCounts]);

    useEffect(() => {
        socket.on('processing-started', ({ knowledgeSourceId }) => {
            setProcessing((prev) => {
                const newState = { ...prev, [knowledgeSourceId]: true };
                localStorage.setItem('processing', JSON.stringify(newState));
                return newState;
            });
        });

        socket.on('knowledge-chunk-created', ({ knowledgeSourceId }) => {
            setChunkCounts((prev) => {
                const newState = { ...prev, [knowledgeSourceId]: (prev[knowledgeSourceId] || 0) + 1 };
                localStorage.setItem('chunkCounts', JSON.stringify(newState));
                return newState;
            });
        });

        socket.on('knowledge-chunk-skipped', ({ knowledgeSourceId }) => {
            setChunkCounts((prev) => {
                const newState = { ...prev, [knowledgeSourceId]: (prev[knowledgeSourceId] || 0) + 1 };
                localStorage.setItem('chunkCounts', JSON.stringify(newState));
                return newState;
            });
        });

        socket.on('processing-completed', ({ knowledgeSourceId }) => {
            setProcessing((prev) => {
                const newState = { ...prev };
                delete newState[knowledgeSourceId];
                localStorage.setItem('processing', JSON.stringify(newState));
                return newState;
            });
        });

        socket.on('processing-cancelled', ({ knowledgeSourceId }) => {
            setProcessing((prev) => {
                const newState = { ...prev };
                delete newState[knowledgeSourceId];
                localStorage.setItem('processing', JSON.stringify(newState));
                return newState;
            });
        });

        return () => {
            socket.off('processing-started');
            socket.off('knowledge-chunk-created');
            socket.off('knowledge-chunk-skipped');
            socket.off('processing-completed');
            socket.off('processing-cancelled');
        };
    }, []);

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                setIsFormVisible(false);
                setIsSearchVisible(false);
                setSearchText('');
                setFilterTypes([]);
            }
        };

        document.addEventListener('keydown', handleEscape);

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, []);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleTypeChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setType((prev) => [...prev, value]);
        } else {
            setType((prev) => prev.filter((type) => type !== value));
        }
    };

    const handleSitemapChange = (event) => {
        setSelectedSitemap(event.target.value);
    };

    const handleFormTypeChange = (event) => {
        setFormType(event.target.value);
    };

    const handleUpload = async (event) => {
        event.preventDefault();
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('name', name);
            formData.append('type', type.join(','));

            try {
                const response = await axios.post('/api/knowledgeSources/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setKnowledgeSources((prev) => [response.data, ...prev.filter(ks => ks.sourceType !== 'sitemap')]);
                setProcessing((prev) => {
                    const newState = { ...prev, [response.data._id]: true };
                    localStorage.setItem('processing', JSON.stringify(newState));
                    return newState;
                });
                setChunkCounts((prev) => {
                    const newState = { ...prev, [response.data._id]: 0 };
                    localStorage.setItem('chunkCounts', JSON.stringify(newState));
                    return newState;
                });
                setFile(null);
                setName('');
                setType([]);
                setIsFormVisible(false);
            } catch (error) {
                console.error('Error uploading knowledge source:', error);
            }
        }
    };

    const handleCreateFromSitemap = async (event) => {
        event.preventDefault();
        if (selectedSitemap) {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.post(
                    '/api/knowledgeSources/createFromSitemap',
                    {
                        sitemapConfigId: selectedSitemap,
                        type: type.join(','),
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setKnowledgeSources((prev) => [response.data, ...prev.filter(ks => ks.sourceType !== 'pdf')]);
                setProcessing((prev) => {
                    const newState = { ...prev, [response.data._id]: true };
                    localStorage.setItem('processing', JSON.stringify(newState));
                    return newState;
                });
                setChunkCounts((prev) => {
                    const newState = { ...prev, [response.data._id]: 0 };
                    localStorage.setItem('chunkCounts', JSON.stringify(newState));
                    return newState;
                });
                setSelectedSitemap('');
                setType([]);
                setIsFormVisible(false);
            } catch (error) {
                console.error('Error creating knowledge source from sitemap:', error);
            }
        }
    };

    const handleUpdate = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(
                `/api/knowledgeSources/update/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setProcessing((prev) => {
                const newState = { ...prev, [id]: true };
                localStorage.setItem('processing', JSON.stringify(newState));
                return newState;
            });
            setChunkCounts((prev) => {
                const newState = { ...prev, [id]: 0 };
                localStorage.setItem('chunkCounts', JSON.stringify(newState));
                return newState;
            });
        } catch (error) {
            console.error('Error updating knowledge source:', error);
        }
    };

    const handleCancel = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(
                `/api/knowledgeSources/cancel/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setProcessing((prev) => {
                const newState = { ...prev };
                delete newState[id];
                localStorage.setItem('processing', JSON.stringify(newState));
                return newState;
            });
        } catch (error) {
            console.error('Error cancelling knowledge source processing:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/api/knowledgeSources/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setKnowledgeSources((prev) => prev.filter((knowledgeSource) => knowledgeSource._id !== id));
            setProcessing((prev) => {
                const newState = { ...prev };
                delete newState[id];
                localStorage.setItem('processing', JSON.stringify(newState));
                return newState;
            });
            setChunkCounts((prev) => {
                const newState = { ...prev };
                delete newState[id];
                localStorage.setItem('chunkCounts', JSON.stringify(newState));
                return newState;
            });
        } catch (error) {
            console.error('Error deleting knowledge source:', error);
        }
    };

    const handleEdit = (id, type, name) => {
        setEditingId(id);
        setEditType(type);
        setEditName(name);
    };

    const handleEditTypeChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setEditType((prev) => [...prev, value]);
        } else {
            setEditType((prev) => prev.filter((t) => t !== value));
        }
    };

    const handleEditNameChange = (event) => {
        setEditName(event.target.value);
    };

    const handleCancelEdit = () => {
        setEditingId(null);
        setEditType([]);
        setEditName('');
    };

    const handleSaveEdit = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(
                `/api/knowledgeSources/${id}`,
                { type: editType, name: editName },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setKnowledgeSources((prev) =>
                prev.map((knowledgeSource) =>
                    knowledgeSource._id === id ? { ...knowledgeSource, type: editType, name: editName } : knowledgeSource
                )
            );
            handleCancelEdit();
        } catch (error) {
            console.error('Error saving knowledge source type:', error);
        }
    };

    const filteredKnowledgeSources = knowledgeSources.filter(source =>
        source.name.toLowerCase().includes(searchText.toLowerCase()) &&
        (filterTypes.length === 0 || filterTypes.some(type => source.type.includes(type)))
    );

    const sortedKnowledgeSources = [...filteredKnowledgeSources].sort((a, b) => new Date(b.dateUploaded) - new Date(a.dateUploaded));
    const sitemapSources = sortedKnowledgeSources.filter(source => source.sourceType === 'sitemap');
    const pdfSources = sortedKnowledgeSources.filter(source => source.sourceType === 'pdf');

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleFilterTypeChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setFilterTypes((prev) => [...prev, value]);
        } else {
            setFilterTypes((prev) => prev.filter((type) => type !== value));
        }
    };

    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
        if (!isFormVisible) setIsSearchVisible(false);
    };

    const toggleSearchVisibility = () => {
        setIsSearchVisible(!isSearchVisible);
        if (!isSearchVisible) {
            setSearchText('');
            setFilterTypes([]);
            setIsFormVisible(false);
        }
    };

    const handleClearSearch = () => {
        setSearchText('');
        setFilterTypes([]);
        setIsSearchVisible(false);
    };

    return (
        <div className="knowledge-page w-4/5 p-8 rounded-xl shadow-xl bg-slate-800 bg-opacity-40 border border-slate-700 text-left mx-auto mt-10">
            <h1 className="text-3xl font-bold text-white mb-2">Knowledge</h1>
            <p className="text-slate-400 mb-4">
                Upload PDFs or select a sitemap to create knowledge chunks.
            </p>
            <div className="mb-6 flex justify-start space-x-4">
                <button
                    onClick={toggleFormVisibility}
                    className="bg-slate-950 border border-slate-700 text-white font-bold text-sm px-4 py-2 rounded hover:bg-slate-800"
                >
                    {isFormVisible ? 'Hide Knowledge Source Form' : 'Create Knowledge Source'}
                </button>
                <button
                    onClick={toggleSearchVisibility}
                    className="bg-slate-950 border border-slate-700 text-white font-bold text-sm px-4 py-2 rounded hover:bg-slate-800"
                >
                    {isSearchVisible ? 'Hide Search & Filter' : 'Search & Filter'}
                </button>
            </div>

            {isFormVisible && (
                <>
                    <div className={"pl-6 border border-slate-700 p-4 bg-slate-900 rounded-lg shadow-xl"}>
                        <div className="mb-6 flex justify-between">
                            <div>
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        value="pdf"
                                        checked={formType === 'pdf'}
                                        onChange={handleFormTypeChange}
                                        className="mr-2"
                                    />
                                    Upload PDF
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="sitemap"
                                        checked={formType === 'sitemap'}
                                        onChange={handleFormTypeChange}
                                        className="mr-2"
                                    />
                                    Select Sitemap
                                </label>
                            </div>
                            <button
                                onClick={() => setIsFormVisible(false)}
                                className="text-white"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-slate-500 w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>

                        {formType === 'pdf' ? (
                            <form className="mb-4" onSubmit={handleUpload}>
                                <div className="mb-4">
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={handleNameChange}
                                        placeholder="Enter name"
                                        className="w-1/3 mr-2 inline text-sm text-gray-500 border rounded p-2"
                                    />
                                    <input
                                        type="file"
                                        accept=".pdf"
                                        onChange={handleFileChange}
                                        className=" inline text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-sky-400 file:text-white hover:file:bg-sky-300 file:cursor-pointer"
                                    />
                                </div>
                                <div className="mb-4">
                                    <p className="text-sm text-gray-500 mb-2">Select types:</p>
                                    <div className="grid grid-cols-2 gap-2">
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="federal"
                                                checked={type.includes('federal')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Federal
                                        </label>
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="knowledge graph"
                                                checked={type.includes('knowledge graph')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Knowledge Graph
                                        </label>
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="technical"
                                                checked={type.includes('technical')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Technical
                                        </label>
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="product"
                                                checked={type.includes('product')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Product
                                        </label>
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="marketing"
                                                checked={type.includes('marketing')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Marketing
                                        </label>
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="market knowledge"
                                                checked={type.includes('market knowledge')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Market knowledge
                                        </label>
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="customer success"
                                                checked={type.includes('customer success')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Customer Success
                                        </label>

                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="legal"
                                                checked={type.includes('legal')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Legal
                                        </label>
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="ddw fundamentals"
                                                checked={type.includes('ddw fundamentals')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            DDW Fundamentals
                                        </label>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="w-36 bg-lime-500 text-slate-900 font-bold text-sm px-4 py-2 rounded hover:bg-lime-300"
                                >
                                    Upload
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setIsFormVisible(false)}
                                    className="ml-4 w-36 bg-slate-950 text-white font-bold text-sm px-4 py-2 rounded hover:bg-slate-800"
                                >
                                    Cancel
                                </button>
                            </form>
                        ) : (
                            <form className="mb-6" onSubmit={handleCreateFromSitemap}>
                                <div className="mb-4">
                                    <select
                                        value={selectedSitemap}
                                        onChange={handleSitemapChange}
                                        className="w-72 text-sm text-gray-500 border rounded p-2"
                                    >
                                        <option value="" disabled>Select a sitemap</option>
                                        {sitemaps.map((sitemap) => (
                                            <option key={sitemap._id} value={sitemap._id}>
                                                {sitemap.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <p className="text-sm text-gray-500 mb-2">Select types:</p>
                                    <div className="grid grid-cols-2 gap-2">
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="federal"
                                                checked={type.includes('federal')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Federal
                                        </label>
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="knowledge graph"
                                                checked={type.includes('knowledge graph')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Knowledge Graph
                                        </label>
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="technical"
                                                checked={type.includes('technical')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Technical
                                        </label>
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="product"
                                                checked={type.includes('product')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Product
                                        </label>
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="marketing"
                                                checked={type.includes('marketing')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Marketing
                                        </label>
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="market knowledge"
                                                checked={type.includes('market knowledge')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Market knowledge
                                        </label>
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="customer success"
                                                checked={type.includes('customer success')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Customer Success
                                        </label>

                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="legal"
                                                checked={type.includes('legal')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            Legal
                                        </label>
                                        <label className="flex items-center text-sm text-gray-500">
                                            <input
                                                type="checkbox"
                                                value="ddw fundamentals"
                                                checked={type.includes('ddw fundamentals')}
                                                onChange={handleTypeChange}
                                                className="mr-2"
                                            />
                                            DDW Fundamentals
                                        </label>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="w-36 bg-lime-500 text-slate-900 font-bold text-sm px-4 py-2 rounded hover:bg-lime-300"
                                >
                                    Create
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setIsFormVisible(false)}
                                    className="ml-4 w-36 bg-slate-950 text-white font-bold text-sm px-4 py-2 rounded hover:bg-slate-800"
                                >
                                    Cancel
                                </button>
                            </form>
                        )}
                    </div>
                </>
            )}

            {isSearchVisible && (
                <div className="mb-6 pl-6 pb-6 border border-slate-700 p-4 bg-slate-900 rounded-lg shadow-xl relative">
                    <h2 className={"text-white mb-4"}>Search & Filter</h2>
                    <input
                        type="text"
                        value={searchText}
                        onChange={handleSearchTextChange}
                        placeholder="Search by name or filename"
                        className="w-1/3 mb-4 text-sm text-gray-500 border rounded p-2"
                    />
                    <p className="text-sm text-gray-500 mb-2">Filter by types:</p>
                    <div className="grid grid-cols-2 gap-2 mb-4">
                        <label className="flex items-center text-sm text-gray-500">
                            <input
                                type="checkbox"
                                value="federal"
                                checked={filterTypes.includes('federal')}
                                onChange={handleFilterTypeChange}
                                className="mr-2"
                            />
                            Federal
                        </label>
                        <label className="flex items-center text-sm text-gray-500">
                            <input
                                type="checkbox"
                                value="knowledge graph"
                                checked={filterTypes.includes('knowledge graph')}
                                onChange={handleFilterTypeChange}
                                className="mr-2"
                            />
                            Knowledge Graph
                        </label>
                        <label className="flex items-center text-sm text-gray-500">
                            <input
                                type="checkbox"
                                value="technical"
                                checked={filterTypes.includes('technical')}
                                onChange={handleFilterTypeChange}
                                className="mr-2"
                            />
                            Technical
                        </label>
                        <label className="flex items-center text-sm text-gray-500">
                            <input
                                type="checkbox"
                                value="product"
                                checked={filterTypes.includes('product')}
                                onChange={handleFilterTypeChange}
                                className="mr-2"
                            />
                            Product
                        </label>
                        <label className="flex items-center text-sm text-gray-500">
                            <input
                                type="checkbox"
                                value="marketing"
                                checked={filterTypes.includes('marketing')}
                                onChange={handleFilterTypeChange}
                                className="mr-2"
                            />
                            Marketing
                        </label>
                        <label className="flex items-center text-sm text-gray-500">
                            <input
                                type="checkbox"
                                value="market knowledge"
                                checked={filterTypes.includes('market knowledge')}
                                onChange={handleFilterTypeChange}
                                className="mr-2"
                            />
                            Market knowledge
                        </label>
                        <label className="flex items-center text-sm text-gray-500">
                            <input
                                type="checkbox"
                                value="customer success"
                                checked={filterTypes.includes('customer success')}
                                onChange={handleFilterTypeChange}
                                className="mr-2"
                            />
                            Customer Success
                        </label>

                        <label className="flex items-center text-sm text-gray-500">
                            <input
                                type="checkbox"
                                value="legal"
                                checked={filterTypes.includes('legal')}
                                onChange={handleFilterTypeChange}
                                className="mr-2"
                            />
                            Legal
                        </label>
                        <label className="flex items-center text-sm text-gray-500">
                            <input
                                type="checkbox"
                                value="ddw fundamentals"
                                checked={filterTypes.includes('ddw fundamentals')}
                                onChange={handleFilterTypeChange}
                                className="mr-2"
                            />
                            DDW Fundamentals
                        </label>

                    </div>
                    <button
                        onClick={handleClearSearch}
                        className="w-36 bg-slate-950 text-white font-bold text-sm px-4 py-2 rounded hover:bg-slate-800"
                    >
                        Hide Search
                    </button>
                    <button
                        onClick={handleClearSearch}
                        className="absolute top-4 right-4 text-white"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="text-slate-500 w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            )}

            <div className={`mt-6 ${isSearchVisible ? 'grid grid-cols-2 gap-6' : ''}`}>
                <div>
                    <h2 className="text-lg text-white mb-4">Sitemaps</h2>
                    <ul className="list-none p-0">
                        {sitemapSources.length > 0 ? (
                            sitemapSources.map((knowledgeSource) => (
                                <li
                                    key={knowledgeSource._id}
                                    className="flex text-sm justify-between items-center mb-2 py-4 px-4 border rounded border-slate-700 shadow-xl bg-slate-900"
                                >
                                    <div className="flex flex-col">
                                        {editingId === knowledgeSource._id ? (
                                            <>
                                                <input
                                                    type="text"
                                                    value={editName}
                                                    onChange={handleEditNameChange}
                                                    className="mb-2 text-sm text-gray-500 border rounded p-2"
                                                />
                                                <div className="flex items-center">
                                                    <div className="flex flex-wrap">
                                                        {['federal', 'knowledge graph', 'technical', 'product', 'marketing', 'market knowledge', 'customer success', 'legal', 'ddw fundamentals'].map((type) => (
                                                            <label key={type} className="flex items-center text-sm text-gray-500 mr-2">
                                                                <input
                                                                    type="checkbox"
                                                                    value={type}
                                                                    checked={editType.includes(type)}
                                                                    onChange={handleEditTypeChange}
                                                                    className="mr-1"
                                                                />
                                                                {type}
                                                            </label>
                                                        ))}
                                                    </div>
                                                    <button
                                                        onClick={() => handleSaveEdit(knowledgeSource._id)}
                                                        className="bg-lime-500 text-slate-900 px-2 py-1 rounded hover:bg-lime-300 text-sm ml-2"
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        onClick={handleCancelEdit}
                                                        className="bg-red-600 text-white px-2 py-1 rounded hover:bg-red-700 text-sm ml-2"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <span className="">{knowledgeSource.name}</span>
                                                <span className="text-xs font-mono text-white">
                                                    Processed: {new Date(knowledgeSource.dateUploaded).toISOString().split('T')[0]} |
                                                    <span className="ml-2 text-purple-400">Knowledge Chunks: {chunkCounts[knowledgeSource._id] || 0} </span> |
                                                    {processing[knowledgeSource._id] ? (
                                                        <span className="animate-pulse text-sky-400 ml-2">Processing...</span>
                                                    ) : (
                                                        <>
                                                            <span className="text-sky-400 ml-2">Processed successfully</span>
                                                            <span className="block text-slate-400">Type: {knowledgeSource.type.join(', ')}</span>
                                                        </>
                                                    )}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        {!processing[knowledgeSource._id] && (
                                            <>
                                                <button
                                                    onClick={() => handleEdit(knowledgeSource._id, knowledgeSource.type, knowledgeSource.name)}
                                                    className="bg-slate-900 font-sans text-slate-500 px-2 py-1 rounded hover:text-yellow-500 transition-colors duration-200"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                                    </svg>
                                                </button>
                                                {knowledgeSource.sourceType === 'sitemap' && ( // Show update button only for sitemaps
                                                    <button
                                                        onClick={() => handleUpdate(knowledgeSource._id)}
                                                        className="bg-slate-900 font-sans text-slate-500 px-2 py-1 rounded hover:text-blue-500 transition-colors duration-200"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                        </svg>
                                                    </button>
                                                )}
                                                <button
                                                    onClick={() => handleDelete(knowledgeSource._id)}
                                                    className="bg-slate-900 font-sans text-slate-500 px-2 py-1 rounded hover:text-red-600 transition-colors duration-200"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </>
                                        )}
                                        {processing[knowledgeSource._id] && (
                                            <button
                                                onClick={() => handleCancel(knowledgeSource._id)}
                                                className="bg-red-600 font-sans text-white px-2 py-1 rounded hover:bg-red-700 text-sm hover:text-white"
                                            >
                                                Stop
                                            </button>
                                        )}
                                    </div>
                                </li>
                            ))
                        ) : (
                            !isSearchVisible && <p className="text-slate-500">No sitemaps created yet.</p>
                        )}
                    </ul>
                </div>

                <div>
                    <h2 className="text-lg text-white mb-4">PDFs</h2>
                    <ul className="list-none p-0">
                        {pdfSources.length > 0 ? (
                            pdfSources.map((knowledgeSource) => (
                                <li
                                    key={knowledgeSource._id}
                                    className="flex text-sm justify-between items-center mb-2 py-4 px-4 border rounded border-slate-700 shadow-xl bg-slate-900"
                                >
                                    <div className="flex flex-col">
                                        {editingId === knowledgeSource._id ? (
                                            <>
                                                <input
                                                    type="text"
                                                    value={editName}
                                                    onChange={handleEditNameChange}
                                                    className="mb-2 text-sm text-gray-500 border rounded p-2"
                                                />
                                                <div className="flex items-center">
                                                    <div className="flex flex-wrap">
                                                        {['federal', 'knowledge graph', 'technical', 'product', 'marketing', 'market knowledge', 'customer success', 'legal', 'ddw fundamentals'].map((type) => (
                                                            <label key={type} className="flex items-center text-sm text-gray-500 mr-2">
                                                                <input
                                                                    type="checkbox"
                                                                    value={type}
                                                                    checked={editType.includes(type)}
                                                                    onChange={handleEditTypeChange}
                                                                    className="mr-1"
                                                                />
                                                                {type}
                                                            </label>
                                                        ))}
                                                    </div>
                                                    <button
                                                        onClick={() => handleSaveEdit(knowledgeSource._id)}
                                                        className="bg-lime-500 text-slate-900 px-2 py-1 rounded hover:bg-lime-300 text-sm ml-2"
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        onClick={handleCancelEdit}
                                                        className="bg-red-600 text-white px-2 py-1 rounded hover:bg-red-700 text-sm ml-2"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <span>{knowledgeSource.name}</span>
                                                <span className="text-xs font-mono text-white">
                                                    Processed: {new Date(knowledgeSource.dateUploaded).toISOString().split('T')[0]} |
                                                    <span className="ml-2 text-purple-400">Knowledge Chunks: {chunkCounts[knowledgeSource._id] || 0} </span> |
                                                    {processing[knowledgeSource._id] ? (
                                                        <span className="animate-pulse text-sky-400 ml-2">Processing...</span>
                                                    ) : (
                                                        <>
                                                            <span className="text-sky-400 ml-2">Processed successfully</span>
                                                            <span className="block text-slate-400">Type: {knowledgeSource.type.join(', ')}</span>
                                                        </>
                                                    )}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        {!processing[knowledgeSource._id] && (
                                            <>
                                                <button
                                                    onClick={() => handleEdit(knowledgeSource._id, knowledgeSource.type, knowledgeSource.name)}
                                                    className="bg-slate-900 font-sans text-slate-500 px-2 py-1 rounded hover:text-yellow-500 transition-colors duration-200"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                                    </svg>
                                                </button>
                                                {knowledgeSource.sourceType === 'sitemap' && ( // Show update button only for sitemaps
                                                    <button
                                                        onClick={() => handleUpdate(knowledgeSource._id)}
                                                        className="bg-slate-900 font-sans text-slate-500 px-2 py-1 rounded hover:text-blue-500 transition-colors duration-200"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                        </svg>
                                                    </button>
                                                )}
                                                <button
                                                    onClick={() => handleDelete(knowledgeSource._id)}
                                                    className="bg-slate-900 font-sans text-slate-500 px-2 py-1 rounded hover:text-red-600 transition-colors duration-200"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </>
                                        )}
                                        {processing[knowledgeSource._id] && (
                                            <button
                                                onClick={() => handleCancel(knowledgeSource._id)}
                                                className="bg-red-600 font-sans text-white px-2 py-1 rounded hover:bg-red-700 text-sm hover:text-white"
                                            >
                                                Stop
                                            </button>
                                        )}
                                    </div>
                                </li>
                            ))
                        ) : (
                            !isSearchVisible && <p className="text-slate-500">No PDFs created yet.</p>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Knowledge;
