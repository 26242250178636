import React, { useEffect, useState } from 'react';
import axios from 'axios';

function AdminDashboard() {
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState({ username: '', email: '', password: '', isAdmin: false });
    const [editingUserId, setEditingUserId] = useState(null);
    const [editedUser, setEditedUser] = useState({ username: '', email: '', password: '', isAdmin: false });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/api/auth/users', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setUsers(response.data);
        } catch (error) {
            console.error('Failed to fetch users:', error);
        }
        setLoading(false);
    };

    const handleCreateUser = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await axios.post('/api/auth/register', newUser, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setNewUser({ username: '', email: '', password: '', isAdmin: false });
            fetchUsers(); // Refresh the list of users
        } catch (error) {
            console.error('Failed to create user:', error);
        }
        setLoading(false);
    };

    const startEditing = (user) => {
        setEditingUserId(user._id);
        setEditedUser({
            username: user.username,
            email: user.email,
            password: '', // Intentionally left blank for security reasons
            isAdmin: user.isAdmin // Set isAdmin from user properties
        });
    };


    const handleUserChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setEditedUser(prev => ({ ...prev, [name]: newValue }));
    };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            // Handle checkboxes
            if (editingUserId) {
                setEditedUser(prev => ({ ...prev, [name]: checked }));
            } else {
                setNewUser(prev => ({ ...prev, [name]: checked }));
            }
        } else {
            // Handle text inputs
            if (editingUserId) {
                setEditedUser(prev => ({ ...prev, [name]: value }));
            } else {
                setNewUser(prev => ({ ...prev, [name]: value }));
            }
        }
    };


    const handleUpdate = async (e) => {
        e.preventDefault();
        console.log('Updating with data:', editedUser); // Log the data being sent
        try {
            const { data } = await axios.put(`/api/auth/users/${editingUserId}`, editedUser, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            const updatedUsers = users.map(user => user._id === data._id ? data : user);
            setUsers(updatedUsers);
            setEditingUserId(null);
        } catch (error) {
            console.error('Failed to update user:', error);
        }
    };


    const handleDelete = async (userId) => {
        if (!window.confirm('Are you sure you want to delete this user?')) return;
        try {
            await axios.delete(`/api/auth/users/${userId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setUsers(users.filter(user => user._id !== userId));
        } catch (error) {
            console.error('Failed to delete user:', error);
        }
    };

    if (loading) return <p>Loading...</p>;
    return (
        <div className="container mx-auto p-8 text-left text-slate-400 border-slate-600 border mt-12 rounded-lg shadow-2xl">
            <h1 className="text-2xl text-lime-500 font-bold text-left mb-12">Admin Dashboard</h1>
            <h2 className={"text-white text-lg font-bold"}>Add new user </h2>
            <form onSubmit={handleCreateUser} className="mb-6 flex gap-3">
                <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={newUser.username}
                    onChange={(e) => setNewUser({...newUser, username: e.target.value})}
                    className="px-2 py-1 border rounded m-2"
                    required
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={newUser.email}
                    onChange={(e) => setNewUser({...newUser, email: e.target.value})}
                    className="px-2 py-1 border rounded m-2"
                    required
                />
                <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={newUser.password}
                    onChange={(e) => setNewUser({...newUser, password: e.target.value})}
                    className="px-2 py-1 border rounded m-2"
                    required
                />
                <label className="inline-flex items-center">
                    <input
                        type="checkbox"
                        checked={newUser.isAdmin}
                        onChange={handleChange}
                        name="isAdmin"
                        className="form-checkbox"
                    />
                    <span className="ml-2">Admin</span>
                </label>
                <button type="submit" className="bg-lime-500 hover:bg-green-700 text-small text-white font-bold py-1 px-3 rounded">
                    Create User
                </button>
            </form>
            <h2 className={"text-white font-bold text-lg mt-8"}>Existing users </h2>
            <ul className="divide-y divide-slate-700">
                {users.map(user => (
                    <li key={user._id} className="py-4 flex justify-between items-center">
                        {editingUserId === user._id ? (
                            <form onSubmit={handleUpdate} className="flex gap-3">
                                <input
                                    type="text"
                                    name="username"
                                    value={editedUser.username}
                                    onChange={handleUserChange}
                                    className="flex-1 px-2 py-1 border rounded"
                                />
                                <input
                                    type="email"
                                    name="email"
                                    value={editedUser.email}
                                    onChange={handleUserChange}
                                    className="flex-1 px-2 py-1 border rounded"
                                />
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="New password (optional)"
                                    value={editedUser.password}
                                    onChange={handleUserChange}
                                    className="flex-1 px-2 py-1 border rounded"
                                />
                                <label className="inline-flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={editedUser.isAdmin}
                                        onChange={handleChange}
                                        name="isAdmin"
                                        className="form-checkbox"
                                    />
                                    <span className="ml-2">Admin</span>
                                </label>
                                <button type="submit"
                                        className="bg-lime-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded">
                                    Save
                                </button>
                                <button type="button" onClick={() => setEditingUserId(null)}
                                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-4 rounded">
                                    Cancel
                                </button>
                            </form>
                        ) : (
                            <>
                                <span>{user.username} - {user.email} ({user.isAdmin ? 'Admin' : 'User'})</span>
                                <div className="flex gap-2">
                                    <button onClick={() => startEditing(user)}
                                            className="bg-slate-700 hover:bg-yellow-700 text-white font-bold py-1 px-4 rounded">
                                        Edit
                                    </button>
                                    <button onClick={() => handleDelete(user._id)}
                                            className="bg-slate-700 hover:bg-red-700 text-white font-bold py-1 px-4 rounded">
                                        Delete
                                    </button>
                                </div>
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default AdminDashboard;
