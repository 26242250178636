import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

function Navbar({ isAuthenticated, username, onLogout, isAdmin }) {
    const navigate = useNavigate();

    const handleLogoutClick = () => {
        onLogout();
        navigate('/login'); // Redirect to login after logging out
    };

    const activeStyle = {
        borderBottom: '2px solid',
        borderBottomColor: '#a3e635', // Tailwind lime color equivalent
        paddingBottom: '6px',
        color: 'white' // Tailwind lime color equivalent
    };

    return (
        <nav className="p-4 flex justify-between items-center">
            <div className="flex items-center">
                <img src="/vectorizer-icon.png" alt="DDW Logo" className="h-6 mr-2 inline" />
                <NavLink to="/" className="text-lg font-semibold hover:text-white">DDW :: Vectorizer-2</NavLink>
            </div>
            <div>
                {isAuthenticated ? (
                    <>
                        {isAdmin && (
                            <>
                                <NavLink to="/" end className="text-sm text-slate-400 mr-4 hover:text-white" style={({ isActive }) => isActive ? activeStyle : {}}>Sitemaps</NavLink>
                                <NavLink to="/knowledge" className="text-sm text-slate-400 mr-4 hover:text-white" style={({ isActive }) => isActive ? activeStyle : {}}>Knowledge</NavLink>
                                <NavLink to="/admin" className="text-sm text-slate-400 mr-4 hover:text-white" style={({ isActive }) => isActive ? activeStyle : {}}>Admin</NavLink>
                            </>
                        )}
                        <button onClick={handleLogoutClick} className="text-slate-400 hover:text-red-500 text-sm">Logout</button>
                    </>
                ) : (
                    <NavLink to="/login" className="hover:text-lime-400 mr-4">Login</NavLink>
                )}
            </div>
        </nav>
    );
}

export default Navbar;
