export const decodeToken = (token) => {
    if (!token) return null;
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const decodedValue = JSON.parse(window.atob(base64));
        console.log("Decoded JWT Payload:", decodedValue);
        return decodedValue;
    } catch (e) {
        console.error("Failed to decode token:", e);
        return null;
    }
};
