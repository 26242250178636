// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import RegisterForm from "./components/RegisterForm";
import LoginForm from "./components/LoginForm";
import AdminDashboard from './components/AdminDashboard';
import Knowledge from './components/Knowledge';
import ManualSitemapForm from './components/ManualSitemapForm'; // Import the ManualSitemapForm component
import EditManualSitemapForm from './components/EditManualSitemapForm';
import SitemapApiForm from './components/SitemapApiForm';
import './App.css';
import './index.css';
import { decodeToken } from './utils';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [username, setUsername] = useState('');
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const userData = decodeToken(token);
            const isTokenExpired = userData.exp * 1000 < Date.now();

            if (isTokenExpired) {
                handleLogout();
            } else {
                setUser(userData);
                setIsAuthenticated(true);
                setUsername(localStorage.getItem('username') || '');
            }
        }
    }, []);

    const handleLoginSuccess = (token, username) => {
        localStorage.setItem('token', token);
        localStorage.setItem('username', username);
        const userData = decodeToken(token);
        setUser(userData);
        setIsAuthenticated(true);
        setUsername(username);
    };

    const handleRegisterSuccess = (data) => {
        localStorage.setItem('token', data.token);
        localStorage.setItem('username', data.username);
        setIsAuthenticated(true);
        setUsername(data.username);
        window.location.href = '/';
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        setIsAuthenticated(false);
        setUser(null);
        setUsername('');
    };

    return (
        <Router>
            <div className="App text-lime-400">
                <Navbar isAuthenticated={isAuthenticated} username={username} onLogout={handleLogout} isAdmin={user?.isAdmin} />
                <Routes>
                    <Route path="/" element={<Home isAuthenticated={isAuthenticated} />} />
                    <Route path="/login" element={<LoginForm onLoginSuccess={handleLoginSuccess} />} />
                    <Route path="/edit-manual-sitemap/:id" element={<EditManualSitemapForm />} />
                    <Route path="/manual-sitemap" element={<ManualSitemapForm />} />
                    <Route path="/sitemap-api" element={<SitemapApiForm />} />
                    {user?.isAdmin && <Route path="/admin" element={<AdminDashboard />} />}
                    {user?.isAdmin && <Route path="/knowledge" element={<Knowledge />} />}
                </Routes>
            </div>
        </Router>
    );
}

export default App;