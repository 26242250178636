import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function EditManualSitemapForm() {
    const { id } = useParams();
    const [formData, setFormData] = useState({ name: '', manualUrls: '', tags: '', classes: '', type: '' });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSitemap = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`/api/sitemaps/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP status ${response.status}`);
                }

                const contentType = response.headers.get('content-type');
                if (contentType && contentType.includes('application/json')) {
                    const sitemap = await response.json();
                    console.log('Fetched sitemap:', sitemap); // Debugging log
                    setFormData({
                        name: sitemap.name,
                        manualUrls: sitemap.manualUrls.join('\n'),
                        tags: sitemap.tags.join(', '),
                        classes: sitemap.classes.join(', '),
                        type: sitemap.vectorizerApproach
                    });
                } else {
                    const text = await response.text();
                    console.error('Expected JSON, received:', text);
                    throw new Error('Unexpected response format');
                }
            } catch (error) {
                console.error('Error fetching sitemap:', error);
            }
        };
        fetchSitemap();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        const manualUrlsArray = formData.manualUrls.split('\n').map(url => url.trim());
        const tagsArray = formData.tags.split(',').map(tag => tag.trim());
        const classesArray = formData.classes.split(',').map(cls => cls.trim());

        const sitemapData = {
            name: formData.name,
            url: "manual-url",
            vectorizerApproach: formData.type,
            manualUrls: manualUrlsArray,
            tags: tagsArray,
            classes: classesArray
        };

        try {
            const response = await fetch(`/api/sitemaps/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(sitemapData)
            });

            if (response.ok) {
                navigate('/');
            } else {
                const errorText = await response.text();
                console.error('Failed to update sitemap', response.status, errorText);
            }
        } catch (error) {
            console.error('Error updating sitemap:', error);
        }
    };

    const getTextColorClass = (value) => {
        return value ? 'text-gray-300' : 'text-gray-500';
    };

    const handleCancel = () => {
        navigate('/');
    };

    return (
        <div className="max-w-2xl mx-auto mt-8 bg-slate-800 bg-opacity-40 p-6 rounded-lg shadow-lg">
            <h1 className="text-2xl font-bold mb-4 text-left text-white">Edit Manual Sitemap</h1>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="mb-4">
                    <label className="block text-gray-300 text-sm font-bold mb-2 text-left" htmlFor="name">
                        Sitemap Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`shadow appearance-none border text-lime-400 border-slate-700 rounded w-full py-2 px-3 bg-slate-900 leading-tight focus:outline-none focus:shadow-outline ${getTextColorClass(formData.name)} placeholder-gray-500`}
                        placeholder="Sitemap Name"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-300 text-sm font-bold mb-2 text-left" htmlFor="manualUrls">
                        Manual URLs (each URL on a new line)
                    </label>
                    <textarea
                        name="manualUrls"
                        value={formData.manualUrls}
                        onChange={handleChange}
                        className={`shadow appearance-none text-lime-400 border border-slate-700 rounded w-full py-2 px-3 bg-slate-900 leading-tight focus:outline-none focus:shadow-outline ${getTextColorClass(formData.manualUrls)} placeholder-gray-500`}
                        placeholder="Enter one URL per line"
                        rows="5"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-300 text-sm font-bold mb-2 text-left" htmlFor="tags">
                        Tags (comma-separated)
                    </label>
                    <input
                        type="text"
                        name="tags"
                        value={formData.tags}
                        onChange={handleChange}
                        className={`shadow appearance-none text-lime-400 border border-slate-700 rounded w-full py-2 px-3 bg-slate-900 leading-tight focus:outline-none focus:shadow-outline ${getTextColorClass(formData.tags)} placeholder-gray-500`}
                        placeholder="Tags (comma-separated)"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-300 text-sm font-bold mb-2 text-left" htmlFor="classes">
                        Classes (comma-separated)
                    </label>
                    <input
                        type="text"
                        name="classes"
                        value={formData.classes}
                        onChange={handleChange}
                        className={`shadow appearance-none text-lime-400 border border-slate-700 rounded w-full py-2 px-3 bg-slate-900 leading-tight focus:outline-none focus:shadow-outline ${getTextColorClass(formData.classes)} placeholder-gray-500`}
                        placeholder="Classes (comma-separated)"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-300 text-sm font-bold mb-2 text-left" htmlFor="type">
                        Vectorizer Approach
                    </label>
                    <select
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        className={`shadow appearance-none border text-lime-400 border-slate-700 rounded w-full py-2 px-3 bg-slate-900 leading-tight focus:outline-none focus:shadow-outline ${getTextColorClass(formData.type)} placeholder-gray-500`}
                        required
                    >
                        <option value="" disabled>Select Vectorizer Approach</option>
                        <option value="SummaryEmbedding">Summary Embedding</option>
                        <option value="FullContentEmbedding">Content Embedding</option>
                    </select>
                </div>
                <div className="flex items-center justify-start">
                    <button
                        type="submit"
                        className="bg-lime-400 hover:bg-lime-300 text-slate-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Save
                    </button>
                    <button
                        type="button"
                        onClick={handleCancel}
                        className="bg-slate-700 text-white ml-2 hover:bg-slate-950 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}

export default EditManualSitemapForm;
