import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ManualSitemapForm() {
    const [formData, setFormData] = useState({ name: '', manualUrls: '', tags: '', classes: '', type: '' });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const decodedToken = JSON.parse(atob(base64));

        const manualUrlsArray = formData.manualUrls.split('\n').map(url => url.trim());
        const tagsArray = formData.tags.split(',').map(tag => tag.trim());
        const classesArray = formData.classes.split(',').map(cls => cls.trim());

        const sitemapData = {
            userId: decodedToken.userId,
            name: formData.name,
            url: "manual-url", // Set default URL for manual sitemaps
            vectorizerApproach: formData.type,
            manualUrls: manualUrlsArray,
            tags: tagsArray,
            classes: classesArray
        };

        try {
            const response = await fetch('/api/sitemaps', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(sitemapData)
            });

            if (response.ok) {
                navigate('/');
            } else {
                throw new Error('Failed to create sitemap');
            }
        } catch (error) {
            console.error('Error creating sitemap:', error);
        }
    };

    const handleCancel = () => {
        navigate('/');
    };

    const getTextColorClass = (value) => {
        return value ? 'text-gray-300' : 'text-gray-500';
    };

    return (
        <div className="max-w-2xl mx-auto mt-8 bg-slate-800 bg-opacity-40 p-6 rounded-lg shadow-lg">
            <h1 className="text-2xl font-bold mb-4 text-left text-white">Create Manual Sitemap</h1>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="mb-4">
                    <label className="block text-gray-300 text-sm font-bold mb-2 text-left" htmlFor="name">
                        Sitemap Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`shadow appearance-none border border-slate-700 rounded w-full py-2 px-3 bg-slate-900 leading-tight focus:outline-none focus:shadow-outline ${getTextColorClass(formData.name)} placeholder-gray-500`}
                        placeholder="Sitemap Name"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-300 text-sm font-bold mb-2 text-left" htmlFor="manualUrls">
                        Manual URLs (each URL on a new line)
                    </label>
                    <textarea
                        name="manualUrls"
                        value={formData.manualUrls}
                        onChange={handleChange}
                        className={`shadow appearance-none border border-slate-700 rounded w-full py-2 px-3 bg-slate-900 leading-tight focus:outline-none focus:shadow-outline ${getTextColorClass(formData.manualUrls)} placeholder-gray-500`}
                        placeholder="Enter one URL per line"
                        rows="5"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-300 text-sm font-bold mb-2 text-left" htmlFor="tags">
                        Tags (comma-separated)
                    </label>
                    <input
                        type="text"
                        name="tags"
                        value={formData.tags}
                        onChange={handleChange}
                        className={`shadow appearance-none border border-slate-700 rounded w-full py-2 px-3 bg-slate-900 leading-tight focus:outline-none focus:shadow-outline ${getTextColorClass(formData.tags)} placeholder-gray-500`}
                        placeholder="Tags (comma-separated)"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-300 text-sm font-bold mb-2 text-left" htmlFor="classes">
                        Classes (comma-separated, no ".")
                    </label>
                    <input
                        type="text"
                        name="classes"
                        value={formData.classes}
                        onChange={handleChange}
                        className={`shadow appearance-none border border-slate-700 rounded w-full py-2 px-3 bg-slate-900 leading-tight focus:outline-none focus:shadow-outline ${getTextColorClass(formData.classes)} placeholder-gray-500`}
                        placeholder="Classes (comma-separated)"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-300 text-sm font-bold mb-2 text-left" htmlFor="type">
                        Vectorizer Approach
                    </label>
                    <select
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        className={`shadow appearance-none border border-slate-700 rounded w-full py-2 px-3 bg-slate-900 leading-tight focus:outline-none focus:shadow-outline ${getTextColorClass(formData.type)} placeholder-gray-500`}
                        required
                    >
                        <option value="" disabled>Select Vectorizer Approach</option>
                        <option value="SummaryEmbedding">Summary Embedding</option>
                        <option value="FullContentEmbedding">Content Embedding</option>
                    </select>
                </div>
                <div className="flex items-center justify-start">
                    <button
                        type="submit"
                        className="bg-lime-400 hover:bg-lime-300 text-slate-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Create
                    </button>
                    <button
                        type="button"
                        onClick={handleCancel}
                        className="bg-slate-700 text-white ml-2 hover:bg-slate-950 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ManualSitemapForm;
