import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function LoginForm({ onLoginSuccess }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // Using the environment variable for the API URL
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'; // Default to localhost if not set

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Use the environment variable in the API request
            const { data } = await axios.post(`${API_URL}/api/auth/login`, { username, password });
            if (data.token) {
                onLoginSuccess(data.token, username); // Propagate login success up to manage auth state
                navigate('/'); // Navigate to the homepage on successful login
            } else {
                setError('Login failed. No token received.');
            }
        } catch (error) {
            // Proper error handling
            setError('Failed to login. Please check your credentials.');
        }
    };

    return (
        <div className="flex items-center justify-center">
            <form onSubmit={handleSubmit} className="w-full max-w-sm p-8 rounded-lg shadow-lg">
                <h2 className="text-2xl font-semibold mb-6 text-left">Login</h2>
                <div className="mb-4">
                    <label htmlFor="username" className="block text-left text-sm font-bold mb-2">Username</label>
                    <input id="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)}
                           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                </div>
                <div className="mb-6">
                    <label htmlFor="password" className="block text-left text-sm font-bold mb-2">Password</label>
                    <input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)}
                           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"/>
                    {error && <p className="text-red-500 text-xs italic">{error}</p>}
                </div>
                <div className="flex items-center justify-between">
                    <button type="submit"
                            className="bg-lime-400 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Login
                    </button>
                </div>
            </form>
        </div>
    );
}

export default LoginForm;
